var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { useRouter } from 'vue-router';
import { defineComponent, ref, inject, computed } from 'vue';
import { IonContent, IonCard, IonCardContent, IonCardHeader, IonPage, IonInput, IonItem, IonLabel, IonButton, } from '@ionic/vue';
import Swal from 'sweetalert2';
import i18n from '@/i18n';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
var componentName = 'Login';
var components = {
    IonContent: IonContent,
    IonCard: IonCard,
    IonCardContent: IonCardContent,
    IonCardHeader: IonCardHeader,
    IonPage: IonPage,
    IonInput: IonInput,
    IonItem: IonItem,
    IonLabel: IonLabel,
    IonButton: IonButton,
};
var setup = function () {
    var auth = inject('authState');
    var router = useRouter();
    var error = ref(false);
    var errormsg = ref('');
    var loading = ref(false);
    var appVersion = ref('');
    var _a = useField('email', yup
        .string()
        .email(function () { return i18n.global.t('tester_form_valid_email'); })
        .required(function () { return i18n.global.t('tester_required_email'); })), errorMail = _a.errorMessage, email = _a.value;
    var _b = useField('password', yup.string().required(function () { return i18n.global.t('tester_required_password'); })), errorPwd = _b.errorMessage, password = _b.value;
    var formValid = computed(function () {
        return !!email.value &&
            !!password.value &&
            !errorMail.value &&
            !errorPwd.value;
    });
    var login = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, loggedIn, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 7, , 8]);
                    if (!formValid.value)
                        return [2 /*return*/];
                    loading.value = true;
                    if (Capacitor.isNativePlatform()) {
                        AppVersion.getVersionNumber().then(function (appversion) {
                            appVersion.value = appversion;
                        });
                    }
                    payload = {
                        email: email.value,
                        password: password.value,
                        appVersion: appVersion.value
                    };
                    return [4 /*yield*/, auth.logUserIn(payload)];
                case 1:
                    loggedIn = _a.sent();
                    if (!!loggedIn.success) return [3 /*break*/, 2];
                    Swal.fire({
                        icon: 'error',
                        title: loggedIn.message,
                    });
                    loading.value = false;
                    return [2 /*return*/];
                case 2:
                    if (!(loggedIn.nextStep == 'testWall')) return [3 /*break*/, 3];
                    router.push({ name: 'tests.index' });
                    return [3 /*break*/, 6];
                case 3:
                    if (!(loggedIn.nextStep == 'validate2fa')) return [3 /*break*/, 5];
                    return [4 /*yield*/, Preferences.set({
                            key: 'needs2FA',
                            value: Date.now().toString(),
                        })];
                case 4:
                    _a.sent();
                    router.push({ name: 'validate2fa' });
                    return [3 /*break*/, 6];
                case 5:
                    if (loggedIn.nextStep == 'add-mobile') {
                        router.push({ name: 'add-mobile' });
                    }
                    else if (loggedIn.nextStep == 'unique-validation') {
                        router.push({ name: 'unique-validation' });
                    }
                    _a.label = 6;
                case 6:
                    setTimeout(function () {
                        loading.value = false;
                    }, 1000);
                    return [3 /*break*/, 8];
                case 7:
                    error_1 = _a.sent();
                    loading.value = false;
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var getCurrentYear = computed(function () {
        return new Date().getFullYear();
    });
    return {
        email: email,
        password: password,
        error: error,
        errormsg: errormsg,
        login: login,
        errorMail: errorMail,
        errorPwd: errorPwd,
        formValid: formValid,
        loading: loading,
        getCurrentYear: getCurrentYear,
    };
};
export default defineComponent({ name: componentName, components: components, setup: setup });
